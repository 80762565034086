#wrapper {
  display: flex;
  flex-direction: column;
}

.card-head-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-head-title {
  margin: 0 !important;
  white-space: normal !important;
  word-break: break-word;
}

.card-head-subtitle {
  margin: 0 0 0 10px !important;
  color: grey;
  white-space: normal !important;
  text-align: right;
  word-break: normal;
}

.card-head-tag {
  margin: 0 0 0 10px;
}

@media screen and (min-width: 768px) {
  #wrapper {
    padding: 30px 30px 0 30px;
  }
}

/* Gets rid of blue background for menu bar */
.ant-menu-dark .ant-menu-item:hover {
  background-color: transparent !important;
}

/* Makes dropdown get smaller on mobile */
.ui.selection.dropdown {
  min-width: 0 !important;
}

/* Allows question mark icon to be on same line as label in form */
.ui.form .field > label {
  display: inline !important;
}
