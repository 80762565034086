#detail-list {
  margin-top: 15px;
  margin-right: 15px;
}

#detail-list .ant-card-head-title {
  overflow: auto;
  white-space: unset;
  text-overflow: unset;
}

.action-button {
  margin-right: 15px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 650px) {
  div.ant-collapse-extra {
    float: unset !important;
    margin-top: 5px !important;
  }
}
